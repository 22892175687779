import React from "react";

const SeaPage: React.FC = () => {
  return (
    <section className="bg-white">
      <div className="container mx-auto shadow-sm overflow-hidden">
        <div className="flex flex-col justify-center mx-auto text-black py-6 lg:py-8">
          <div className="mx-auto px-4 flex flex-col items-center  pb-6 lg:pb-8">
            <h2 className="text-3xl font-bold text-sky-800">Sea Freight</h2>
          </div>
          <div className="mx-auto px-4 flex flex-col text-left">
            <p className="mt-4">
              Basically Limitless offers Air Freight services across the region.
              We transport cargo from major global economies such as Dubai -
              U.A.E, China, USA, India, Turkey, Pakistan, South Africa, and
              Thailand to East Africa, including Somalia, Kenya, Uganda,
              Tanzania, Somaliland, and Djibouti. To get started, deliver a
              well-labeled cargo to any of our offices, and we will forward it
              to its final destination. The duration for most routes is between
              3 days and one and a half weeks. Our busy routes, like Dubai to
              Kenya, have two flights per week, every Monday and Wednesday,
              making a three-day delivery possible.
            </p>
            <p className="mt-4">
              Having established strong relationships with industry players, we
              take great pride in offering competitive rates for our services.
              To see all our rates, you can request a quote on our{" "}
              <a href="/quote" className="text-blue-800 underline font-bold">
                {" "}
                quotes page
              </a>
              .
            </p>
            <p className="mt-4 font-bold">How should I label my cargo?</p>
            <p className="mt-4">
              Label your cargo with the receiver's name, phone number, mode of
              shipping (Air/Sea), and final destination (e.g., Nairobi, Kenya).
            </p>
            <p className="mt-4 font-bold">
              How can I make payment for my cargo?
            </p>
            <p className="mt-4">
              You can make payment at the origin office upon delivering the
              cargo, or if you opt for our pickup services, payment can also be
              made at that time. We accept cash or online payment methods.
            </p>
            <p className="mt-4 font-bold">
              How can I confirm when my cargo has arrived?
            </p>
            <p className="mt-4">
              Once your cargo arrives and is ready for collection, our office
              will contact either the recipient or the sender upon request. You
              can also opt for doorstep delivery for a minimal additional fee.
            </p>
            <p className="mt-4 font-bold">
              From which towns in Kenya can I collect my cargo?
            </p>
            <p className="mt-4">
              You can label the final destination as any of these towns, so you
              can collect from the respective office: Mombasa, Nairobi, Nakuru,
              Eldoret, Kisumu.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SeaPage;
