import { useState } from "react";
import { useForm } from "@formspree/react";

function QuoteForm() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    destination: "",
    pickUp: "",
    weight: "",
    length: "",
    width: "",
    height: "",
    service: "",
    fax: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateInput = () => {
    let errors: any = {};
    if (!formData.fullName.trim()) errors.fullName = "Full Name is required";
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.phone.trim()) errors.phone = "Phone Number is required";
    if (!formData.destination.trim()) {
      errors.destination = "Destination is required";
    }
    if (!formData.pickUp.trim()) {
      errors.pickUp = "Pick Up is required";
    }
    if (!formData.weight.trim()) {
      errors.weight = "Weight is required";
    }
    if (!formData.length.trim()) {
      errors.length = "Length is required";
    }
    if (!formData.width.trim()) {
      errors.width = "Width is required";
    }
    if (!formData.height.trim()) {
      errors.height = "Height is required";
    }
    if (!formData.message.trim()) {
      errors.message = "message is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const formId = process.env.REACT_APP_FORMSPREE_FORM_ID;
  const [state, handleFormHandleSubmit] = useForm(formId);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (formData.fax || formData.fax.toLowerCase().includes("hello")) {
      console.log(
        "Bot submission detected or unwanted text found in the fax field."
      );
      return;
    }
    const isValid = validateInput();
    if (!isValid) return;
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
      alert("Form submitted successfully!");
      setFormData({
        fullName: "",
        email: "",
        phone: "",
        destination: "",
        pickUp: "",
        weight: "",
        length: "",
        width: "",
        height: "",
        service: "",
        fax: "",
        message: "",
      });
    }, 3000);
  };
  const customHandleSubmit = (e: any) => {
    if (e.target.fax.value !== "") {
      return;
    }
    handleFormHandleSubmit(e);
    handleSubmit(e);
  };

  return (
    <section className="flex lg:justify-center lg:mx-auto">
      <div className="container mx-auto w-full">
        <div className="w-full bg-white rounded-xl shadow-md overflow-hidden flex flex-col items-center">
          <div className="p-6 text-left flex flex-col lg:flex-row w-full">
            <div className="flex-1">
              <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold py-7">
                Request a Quote
              </div>
              <form className="text-black " onSubmit={customHandleSubmit}>
                <div className="hidden">
                  <label htmlFor="fax">
                    Fax (Please leave this field empty)
                  </label>
                  <input id="fax" name="fax" type="text" autoComplete="off" />
                </div>
                <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4 ">
                  <div className="flex-1">
                    <label htmlFor="fullName" className="mt-2 text-black">
                      Full Name:
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      name="fullName"
                      value={formData.fullName}
                      placeholder="your full name"
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded my-3"
                      disabled={isSubmitting}
                    />
                    {formErrors.fullName && (
                      <p className="text-red-500">{formErrors.fullName}</p>
                    )}
                  </div>
                  <div className="flex-1">
                    <label htmlFor="email" className="mt-2 text-black">
                      Email:
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      placeholder="email"
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded my-3"
                      disabled={isSubmitting}
                    />
                    {formErrors.email && (
                      <p className="text-red-500">{formErrors.email}</p>
                    )}
                  </div>
                  <div className="flex-1">
                    <label htmlFor="phone" className="mt-2 text-black">
                      Phone Number:
                    </label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="phone number"
                      className="w-full p-2 border border-gray-300 rounded my-3"
                      disabled={isSubmitting}
                    />
                    {formErrors.phone && (
                      <p className="text-red-500">{formErrors.phone}</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4 ">
                  <div className="flex-1">
                    <label htmlFor="destination" className="mt-2 text-black ">
                      Destination:
                    </label>
                    <input
                      type="text"
                      id="destination"
                      name="destination"
                      value={formData.destination}
                      onChange={handleChange}
                      placeholder="destination address"
                      className="w-full p-2 border border-gray-300 rounded my-3"
                      disabled={isSubmitting}
                    />
                    {formErrors.destination && (
                      <p className="text-red-500">{formErrors.destination}</p>
                    )}
                  </div>
                  <div className="flex-1">
                    <label htmlFor="pickUp" className="mt-2 text-black ">
                      Pick Up:
                    </label>
                    <input
                      type="text"
                      id="pickUp"
                      name="pickUp"
                      value={formData.pickUp}
                      onChange={handleChange}
                      placeholder="pick up location"
                      className="w-full p-2 border border-gray-300 rounded my-3"
                      disabled={isSubmitting}
                    />
                    {formErrors.pickUp && (
                      <p className="text-red-500">{formErrors.pickUp}</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4  ">
                  <div className="flex-1">
                    <label htmlFor="service" className="mt-2 text-black ">
                      Service:
                    </label>
                    <select
                      id="service"
                      name="service"
                      value={formData.service}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded my-3"
                      disabled={isSubmitting}
                    >
                      <option value="">Select Service</option>
                      <option value="air">Air Cargo</option>
                      <option value="sea">Ocean Cargo</option>
                      <option value="road">Road</option>
                      <option value="express">express</option>
                      <option value="warehousing">Warehousing</option>
                    </select>
                  </div>
                  <div className="flex-1">
                    <label htmlFor="weight" className="mt-2 text-black ">
                      Weight:
                    </label>
                    <input
                      type="text"
                      id="weight"
                      name="weight"
                      value={formData.weight}
                      onChange={handleChange}
                      placeholder="e.g., 20 kg"
                      className="w-full p-2 border border-gray-300 rounded my-3"
                      disabled={isSubmitting}
                    />
                    {formErrors.weight && (
                      <p className="text-red-500">{formErrors.weight}</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4  ">
                  <div className="flex-1">
                    <label htmlFor="length" className="mt-2 text-black ">
                      Length:
                    </label>
                    <input
                      type="text"
                      id="length"
                      name="length"
                      value={formData.length}
                      onChange={handleChange}
                      placeholder="e.g., 20 cm"
                      className="w-full p-2 border border-gray-300 rounded my-3"
                      disabled={isSubmitting}
                    />
                    {formErrors.length && (
                      <p className="text-red-500">{formErrors.length}</p>
                    )}
                  </div>
                  <div className="flex-1">
                    <label htmlFor="width" className="mt-2 text-black ">
                      Width:
                    </label>
                    <input
                      type="text"
                      id="width"
                      name="width"
                      value={formData.width}
                      onChange={handleChange}
                      placeholder="e.g., 20 cm"
                      className="w-full p-2 border border-gray-300 rounded my-3"
                      disabled={isSubmitting}
                    />
                    {formErrors.width && (
                      <p className="text-red-500">{formErrors.width}</p>
                    )}
                  </div>
                  <div className="flex-1">
                    <label htmlFor="height" className="mt-2 text-black ">
                      Height:
                    </label>
                    <input
                      type="text"
                      id="height"
                      name="height"
                      value={formData.height}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded my-3"
                      placeholder="e.g., 20 cm"
                      disabled={isSubmitting}
                    />
                    {formErrors.height && (
                      <p className="text-red-500">{formErrors.height}</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4  ">
                  <div className="flex-1">
                    <label htmlFor="message" className="mt-2 text-black ">
                      message:
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="write your message here..."
                      className="w-full p-2 border border-gray-300 rounded my-3"
                    />
                    {formErrors.message && (
                      <p className="text-red-500">{formErrors.message}</p>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-indigo-500 hover:bg-indigo-300  focus:ring-4 focus:ring-indigo-500 dark:focus:ring-indigo-700 w-full md:w-auto"
                  disabled={state.submitting || isSubmitting}
                >
                  Request Quote
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-send ml-2 h-5 w-5"
                  >
                    <line x1="22" y1="2" x2="11" y2="13"></line>
                    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                  </svg>
                </button>
              </form>
            </div>
            <div className="flex flex-1 items-center">
              <div className="lg:py-7 lg:px-4 py-4 px-0">
                <h2 className="font-bold text-lg mb-4">Package Information</h2>
                <div className="space-y-2">
                  <p>
                    <span className="font-semibold">Full Name:</span> Your
                    complete name.
                  </p>
                  <p>
                    <span className="font-semibold">Email:</span> Your valid
                    email address.
                  </p>
                  <p>
                    <span className="font-semibold">Phone Number:</span> Contact
                    number with country code.
                  </p>
                  <p>
                    <span className="font-semibold">Destination:</span> Delivery
                    address or city.
                  </p>
                  <p>
                    <span className="font-semibold">Pick Up:</span> Pick-up
                    address or location.
                  </p>
                  <p>
                    <span className="font-semibold">Package Details:</span>{" "}
                    Weight (kg), dimensions (cm), and any special instructions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default QuoteForm;
