import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import NotFoundPage from "./components/404/NotFoundPage";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import RoadPage from "./pages/services/RoadPage";
import AirPage from "./pages/services/AirPage";
import SeaPage from "./pages/services/SeaPage";
import FulfillmentPage from "./pages/services/FulfillmentPage";
import WarehousingPage from "./pages/services/WarehousingPage";
import PageTitle from "./components/pageTitle/PageTitle";
import QuotePage from "./pages/QuotePage";
import ContactPage from "./pages/ContactPage";

function App() {
  return (
    <Router>
      <Navbar />

      <main className="bg-white min-h-screen">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <PageTitle title="Basically Limitless" />
                <HomePage />
              </>
            }
          />

          <Route
            path="/about"
            element={
              <>
                <PageTitle title="About" />
                <AboutPage />
              </>
            }
          />

          <Route
            path="/services/road"
            element={
              <>
                <PageTitle title="Road Transport Services" />
                <RoadPage />
              </>
            }
          />

          <Route
            path="/services/air"
            element={
              <>
                <PageTitle title="Air Transport Services" />
                <AirPage />
              </>
            }
          />

          <Route
            path="/services/sea"
            element={
              <>
                <PageTitle title="Sea Transport Services" />
                <SeaPage />
              </>
            }
          />

          <Route
            path="/services/fulfillment"
            element={
              <>
                <PageTitle title="Fulfillment Services" />
                <FulfillmentPage />
              </>
            }
          />

          <Route
            path="/services/Warehousing"
            element={
              <>
                <PageTitle title="Warehousing Services" />
                <WarehousingPage />
              </>
            }
          />

          <Route
            path="/quote"
            element={
              <>
                <PageTitle title="Get a Quote" />
                <QuotePage />
              </>
            }
          />

          <Route
            path="/Contact"
            element={
              <>
                <PageTitle title="Contact Us" />
                <ContactPage />
              </>
            }
          />

          <Route
            path="*"
            element={
              <>
                <PageTitle title="Page Not Found" />
                <NotFoundPage />
              </>
            }
          />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
//    <main className="bg-gradient-to-r from-sky-200 via-white to-sky-200 min-h-screen">
// <main className="bg-gradient-to-r from-sky-100 via-slate-200 to-slate-500 min-h-screen">
