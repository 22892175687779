// warehousing service page

import React from "react";
import Card from "../../components/card/card";

const WarehousingPage: React.FC = () => {
  return (
    <div>
      <section className="text-black flex items-center ">
        <div className="mx-auto container flex flex-col items-center">
          <h2 className="text-3xl font-bold text-center text-sky-800 w-full pb-6 lg:pb-8">
            Warehousing Services
          </h2>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 p-4">
              <h4 className="text-lg font-bold mb-2">
                Convenient Pickup Service
              </h4>
              <p>Hassle-Free Pickup at Your Convenience</p>
              <p>
                Our warehousing service offers a convenient pickup option.
                Simply request a pickup, and our team will come to your location
                to collect your items. We ensure safe and efficient
                transportation to our warehouse, so you don’t have to lift a
                finger. Enjoy peace of mind knowing your belongings are handled
                with care.
              </p>
            </div>

            <div className="w-full md:w-1/2 p-4">
              <h4 className="text-lg font-bold mb-2">
                Flexible Drop-Off Option
              </h4>
              <p>Easy Drop-Off at Our Warehouse</p>
              <p>
                Prefer to drop off your items yourself? No problem! You can
                deliver your items directly to our secure warehouse at a time
                that suits you. Our friendly staff will assist with unloading
                and ensure your items are stored safely and securely for the
                duration of your storage period.
              </p>
            </div>

            <div className="w-full md:w-1/2 p-4">
              <h4 className="text-lg font-bold mb-2">
                Secure Storage Solutions
              </h4>
              <p>Safe and Secure Storage</p>
              <p>
                Your items are in good hands with our state-of-the-art
                warehousing facilities. We provide secure, climate-controlled
                storage to protect your belongings from damage. Whether you need
                short-term or long-term storage, our solutions are designed to
                meet your needs with maximum security and reliability.
              </p>
            </div>

            <div className="w-full md:w-1/2 p-4">
              <h4 className="text-lg font-bold mb-2">
                Reliable Delivery Service
              </h4>
              <p>Prompt Delivery to Your Desired Location</p>
              <p>
                When you’re ready to retrieve your items, our reliable delivery
                service ensures they are returned to you promptly and in perfect
                condition. Simply request a delivery, and we’ll bring your items
                back to your location or any other specified address. Experience
                seamless service from start to finish with our warehousing
                solutions.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WarehousingPage;
