import React from "react";
import Card from "../../components/card/card";
const RoadPage = () => {
  return (
    <div>
      <section className="flex text-gray-100 min-h-screen">
        <div className="container mx-auto px-4">
          <div className="relative mx-auto max-w-5xl text-center">
            <h2 className="text-3xl font-bold text-center text-sky-800 w-full pb-6 lg:pb-8">
              Road Transport
            </h2>
          </div>

          <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            <Card
              title="Seamless Road Transport"
              content="For both domestic and international road transport, we ensure your goods reach their destination."
              image="/truck (1).png"
              centered={true}
            />
            <Card
              title="Express Courier Service"
              content="Our express courier service ensures speedy delivery of your documents, parcels, pallets, or any combination required, backed by competitive rates."
              image="/delivery-truck.png"
              centered={true}
            />
            <Card
              title="Tracking"
              content="If you need to know where your shipment is at any time, our delivery drivers' locations are tracked in real-time."
              image="/parcel.png"
              centered={true}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default RoadPage;
