const AirPage = () => {
  return (
    <div>
      <section id="features" className="relative block min-h-vh-100 text-black">
        <div className="container mx-auto px-4">
          <div className="relative mx-auto max-w-5xl text-center">
            <h2 className="block w-full  font-bold text-3xl font-bold text-sky-800">
              AIR FREIGHT
            </h2>
            <p className="mx-auto my-4 w-full max-w-xl bg-transparent leading-relaxed tracking-wide">
              We offer air freight services that are fast, reliable, and
              flexible to meet your specific needs. We can take your cargo by
              air to almost every destination worldwide.
            </p>
          </div>

          <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
            <div className="rounded-md p-8 text-center shadow">
              <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md ">
                <i className="fas fa-globe fa-2x"></i>
              </div>
              <h3 className="mt-6">Global</h3>
              <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide">
                We can take your cargo by air to almost every destination world
                wide.
              </p>
            </div>

            <div className="rounded-md p-8 text-center shadow">
              <div className="button-text mx-auto flex  items-center justify-center">
                <i className="fas fa-clock fa-2x "></i>
              </div>
              <h3 className="mt-6">Time Sensitive</h3>
              <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide">
                When your shipment needs to be on time at location, we make it
                happen.
              </p>
            </div>

            <div className="rounded-md p-8 text-center shadow">
              <div className="button-text mx-auto flex h-12 w-12 items-center justify-center">
                <i className="fas fa-phone fa-2x"></i>
              </div>
              <h3 className="mt-6">Availability</h3>
              <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide">
                You can reach us by phone, email, or our contact form, which is
                available 24/7. We will contact you as soon as possible.
                Alternatively, you can give us a call.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="text-white flex lg:justify-center lg:mx-auto">
        <div className="mx-auto flex flex-col items-center container">
          <h3 className="text-3xl font-bold text-center text-sky-800">
            Request a quote today
          </h3>
          <a
            className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-3xl text-lg px-10 py-4 text-center me-2 mb-2 mt-6"
            href="/quote"
          >
            Get a Quote
          </a>
        </div>
      </section>
    </div>
  );
};

export default AirPage;
