//footer component simple coyright footer

const Footer = () => {
  return (
    <footer className="text-white bg-gray-900 py-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-col  items-center">
          <div className="text-center ">
            <p className="text-sm">
              &copy; Copyright - Basically Limitless cargo - 2024 | All Rights
              Reserved{" "}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
