import React from "react";

interface CardProps {
  image?: string;
  title: string;
  content: string;
  cardColor?: string;
  linkText?: string;
  linkHref?: string;
  streachLink?: boolean;
  hasBorder?: boolean;
  hasShadow?: boolean;
  centered?: boolean; // new property
}

const Card: React.FC<CardProps> = ({
  title,
  content,
  image,
  cardColor,
  linkText,
  linkHref,
  streachLink,
  hasBorder,
  hasShadow,
  centered, // new property
}) => {
  const cardContent = (
    <>
      <div className="w-full p-6">
        <img className="mx-auto w-18  md:w-20 " src={image} alt={title} />
      </div>
      <div className={`p-6 text-left ${centered ? "text-center" : ""}`}>
        <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
          {title}
        </div>
        <p className="mt-2 text-gray-500 ">{content}</p>
        {linkText && (
          <div className={`mt-4 ${streachLink ? "streach-link-class" : ""}`}>
            <a
              href={linkHref || "#"}
              className="text-blue-600 hover:text-blue-800"
            >
              {linkText}
            </a>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div
      className={`max-w-md mx-auto bg-white ${cardColor} ${
        hasBorder ? "rounded-xl" : ""
      } ${
        hasShadow ? "shadow-md" : ""
      } overflow-hidden md:max-w-2xl m-3 flex flex-col items-center`}
    >
      {streachLink ? <a href={linkHref || "#"}>{cardContent}</a> : cardContent}
    </div>
  );
};

export default Card;
