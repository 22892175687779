import React from "react";
import Card from "../../components/card/card";

const FulfillmentPage = () => {
  return (
    <section className="text-white flex flex-col items-center lg:mx-auto">
      <div className="mx-auto container flex flex-col items-center">
        <h2 className="text-3xl font-bold text-center text-sky-800 w-full pb-6 lg:pb-8">
          Global Fulfillment Services
        </h2>
        <div className="md:grid md:grid-cols-2 lg:grid-cols-4 gap-4">
          <Card
            title="Step 1: Worldwide Sourcing"
            content="We access a global network of suppliers to fulfill orders when local inventory is out of stock or unavailable. This ensures that your business can continue to operate smoothly and meet customer demands."
            image="/icon-globe.png" //fas fa-globe
            centered={true}
          />
          <Card
            title="Step 2: Order Processing"
            content="Once an order is placed, we handle the entire order processing. This includes confirming the order, checking inventory, and preparing the product for shipment."
            image="/freight-container.png"
            centered={true}
          />
          <Card
            title="Step 3: Shipping"
            content="After the order is processed, we handle the shipping. We ensure that the product is packed securely and shipped to the correct destination in a timely manner."
            image="/delivery-truck.png"
            centered={true}
          />
          <Card
            title="Step 4: Tracking and Delivery"
            content="Once the product is shipped, we provide tracking information and ensure that the product is delivered to the customer. We also handle any issues that may arise during delivery."
            image="/trade.png"
            centered={true}
          />
        </div>
      </div>
    </section>
  );
};

export default FulfillmentPage;
