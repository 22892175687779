//create home page

import React from "react";
import Card from "../components/card/card";
import HeroBanner from "../components/banner/heroBanner";

import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <div>
      <HeroBanner
        ctaLink="/contact"
        ctaText="Contact Us"
        title="Welcome to Basically Limitless"
        content="We are a logistics company that offers a wide range of services to meet your specific needs. Our services include air freight cargo, ocean freight cargo, road transport, and warehousing. We are committed to providing you with the best solutions to meet your logistics needs. Contact us today to learn more about how we can help you with your logistics needs"
        //image="/3d-rendering-dark-earth-space.png"
        rightImage="/3d-rendering-dark-earth-space.png"
      />
      <section className="text-white flex flex-col items-center lg:mx-auto">
        <div className="mx-auto container flex flex-col items-center">
          <h2 className="text-3xl font-bold text-center text-sky-800 w-full pb-6 lg:pb-8">
            Our Services
          </h2>
          <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            <Card
              title="Air Freight Services"
              content="By air freight, we can offer different delivery times, routings, handling procedures and rates, making it easy to match any of your needs."
              image="/air-plane.png"
              linkHref="/services/air"
              streachLink={true}
              hasBorder={true}
              hasShadow={true}
            />
            <Card
              title="Ocean Freight Services"
              content="We offer flexible and efficient options for all your ocean freight cargo needs. You will receive optimal solutions to meet your specific requirements. "
              image="/freight-container.png"
              linkHref="/services/sea"
              streachLink={true}
              hasBorder={true}
              hasShadow={true}
            />
            <Card
              title="Road Transport Services"
              content="We offer road transport services in the Netherlands and Europe. Our road transport services are designed to meet your specific needs and requirements."
              image="/delivery-truck.png"
              linkHref="/services/road"
              streachLink={true}
              hasBorder={true}
              hasShadow={true}
            />
            <Card
              title="Warehousing Services"
              content="We offer warehousing services in the Netherlands, ensuring that your products are stored safely and securely. Our warehousing services are designed to meet your specific needs and requirements."
              image="/warehouse.png"
              linkHref="/services/warehousing"
              streachLink={true}
              hasBorder={true}
              hasShadow={true}
            />
            <Card
              title="Fulfillment Services"
              content="We offer global fulfillment services to provide you with products from around the world which are not available locally. We handle the entire order processing, shipping, tracking, and delivery."
              image="/trade.png"
              linkHref="/services/fulfillment"
              streachLink={true}
              hasBorder={true}
              hasShadow={true}
            />
          </div>
        </div>
      </section>

      <section className="text-white flex lg:justify-center lg:mx-auto">
        <div className="mx-auto flex flex-col items-center container">
          <h2 className="text-3xl font-bold text-center text-sky-800">
            Try our services today
          </h2>
          <Link
            to="/quote"
            className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-3xl text-sm p-6 text-center me-2 mb-2 mt-6"
          >
            Get a Quote
          </Link>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
