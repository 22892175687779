import { useState } from "react";
import { useForm } from "@formspree/react";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
    fax: "",
  });

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateInput = () => {
    let errors: any = {};
    if (!formData.fullName.trim()) errors.fullName = "Full Name is required";
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.phone.trim()) errors.phone = "Phone Number is required";
    if (!formData.message.trim()) {
      errors.message = "message is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const formId = process.env.REACT_APP_FORMSPREE_FORM_CONTACT_ID;
  const [state, handleFormHandleSubmit] = useForm(formId);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (formData.fax) {
      return;
    }
    const isValid = validateInput();
    if (!isValid) return;
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
      alert("Form submitted successfully!");
      setFormData({
        fullName: "",
        email: "",
        phone: "",
        message: "",
        fax: "",
      });
    }, 3000);
  };
  const customHandleSubmit = (e: any) => {
    if (e.target.fax.value !== "") {
      return;
    }
    handleFormHandleSubmit(e);
    handleSubmit(e);
  };

  return (
    <section className="text-gray-100 pt-10 min-h-screen">
      <div className="container py-6 lg:py-12 text-center">
        <h2 className="text-3xl font-bold text-sky-800"> Contact Us</h2>
      </div>
      <div className="container flex flex-col lg:flex-row lg:mx-auto text-black">
        <div className="w-full rounded p-4 lg:p-8 bg-white ">
          <div className="mb-4">
            <h4 className="mb-2">We'd love to hear from you!</h4>
          </div>

          <div className="mb-4">
            <label className="block   font-bold mb-2" htmlFor="email">
              Email
            </label>
            <p className=" ">support@basicallylimitlesscargo.com</p>
          </div>
          <div className="mb-4">
            <label className="block   font-bold mb-2" htmlFor="phone">
              Phone
            </label>
            <p className=" ">+31 618211288</p>
            <p className=" ">+31 624177663</p>
          </div>
          <div className="mb-4">
            <label className="block   font-bold mb-2" htmlFor="other">
              Address
            </label>
            <p className="  text-wrap">
              <span className="my-2 block">Warande </span>
              <span className="my-2 block">3155BS Maasland</span>
              <span className="my-2 block">The Netherlands</span>
            </p>
          </div>
        </div>
        <div className="w-full p-4 lg:p-8 text-white shadow-lg bg-gradient-to-r from-blue-400 to-blue-400">
          <div className="mb-4">
            <h2 className="text-2xl font-bold mb-2 text-gray-700">
              Send us a message
            </h2>
          </div>
          <form onSubmit={customHandleSubmit}>
            <div className="hidden">
              <label htmlFor="fax">Fax (Please leave this field empty)</label>
              <input id="fax" name="fax" type="text" autoComplete="off" />
            </div>
            <div className="mb-4">
              <label className="block   font-bold mb-2" htmlFor="name">
                Name
              </label>
              {formErrors.fullName && (
                <span className="text-red-500">{formErrors.fullName}</span>
              )}
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                type="text"
                placeholder="Your name"
              />
            </div>
            <div className="mb-4">
              <label className="block   font-bold mb-2" htmlFor="phone">
                Phone Number
              </label>
              {formErrors.phone && (
                <span className="text-red-500">{formErrors.phone}</span>
              )}
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline"
                id="phone"
                type="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Your phone number"
              />
            </div>
            <div className="mb-4">
              <label className="block   font-bold mb-2" htmlFor="email">
                Email
              </label>
              {formErrors.email && (
                <span className="text-red-500">{formErrors.email}</span>
              )}
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                name="email"
                placeholder="Your email"
              />
            </div>
            <div className="mb-4">
              <label className="block   font-bold mb-2" htmlFor="message">
                Message
              </label>
              {formErrors.message && (
                <span className="text-red-500">{formErrors.message}</span>
              )}
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline "
                id="message"
                value={formData.message}
                onChange={handleChange}
                name="message"
                placeholder="Your message here..."
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="inline-flex items-center justify-center px-5 py-3 text-base 
                font-medium text-center text-white rounded-lg bg-indigo-500 hover:bg-indigo-300 
                focus:ring-4 focus:ring-indigo-500 dark:focus:ring-indigo-700 w-full md:w-auto"
                disabled={state.submitting || isSubmitting}
              >
                Send Message
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-send ml-2 h-5 w-5"
                >
                  <line x1="22" y1="2" x2="11" y2="13"></line>
                  <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
