import React, { useState } from "react";
import { Link } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-white py-2 lg:px-8 lg:py-4 sticky top-0 z-20">
      <div className=" flex flex-col lg:flex-row justify-between items-center container lg:mx-auto">
        <div className="flex justify-between w-full lg:w-auto">
          <Link
            to="/"
            className="text-2xl font-bold text-black hover:text-blue-800"
          >
            <img src="/logo-black (1).png" alt="logo" className="h-16" />
          </Link>
          <button
            type="button"
            className="inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 -gray-600 lg:hidden"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-8 w-8 fill-current"
            >
              {isOpen ? (
                <path fillRule="evenodd" d="M6 18L18 6M6 6l12 12"></path>
              ) : (
                <path fillRule="evenodd" d="M4 6h16M4 12h16M4 18h16"></path>
              )}
            </svg>
          </button>
        </div>
        <div
          className={`lg:flex ${isOpen ? "block" : "hidden"} lg:w-auto w-full`}
        >
          <ul className="flex flex-col lg:flex-row items-start w-full lg:w-auto">
            <li className="mt-4 md:mt-0  w-full lg:w-auto">
              <Link
                to="/"
                className="block lg:inline p-4 text-black hover:text-blue-800 text-lg leading-6 navbar-link w-full lg:w-auto hover:bg-gray-100 border-r-4 border-transparent hover:border-sky-800"
                onClick={closeMenu}
              >
                Home
              </Link>
            </li>
            <li className="mt-4 md:mt-0  w-full lg:w-auto ">
              <Link
                to="/about"
                className="block lg:inline p-4 text-black hover:text-blue-800 text-lg leading-6 navbar-link w-full lg:w-auto"
                onClick={closeMenu}
              >
                About
              </Link>
            </li>
            <li
              className="mt-4 md:mt-0  relative w-full lg:w-auto"
              onMouseEnter={() => setDropdownOpen(true)}
              onMouseLeave={() => setDropdownOpen(false)}
            >
              <Link
                to="#"
                className="block lg:inline p-4 text-black hover:text-blue-800 text-lg leading-6 navbar-link w-full lg:w-auto"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Services
              </Link>
              <ul
                className={`dropdown absolute left-0 mt-2 p-4 w-52 bg-white rounded-lg shadow-md z-20 ${
                  isDropdownOpen ? "block" : "hidden"
                }`}
              >
                <li>
                  <Link
                    to="/services/sea"
                    className="block p-4 text-black hover:text-blue-800 text-lg leading-6  hover:border-r-4 hover:border-sky-800 w-full lg:w-auto"
                    onClick={closeMenu}
                  >
                    Ocean
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/road"
                    className="block p-4 text-black hover:text-blue-800 text-lg leading-6  hover:border-r-4 hover:border-sky-800 w-full lg:w-auto"
                    onClick={closeMenu}
                  >
                    Road
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/air"
                    className="block p-4 text-black hover:text-blue-800 text-lg leading-6  hover:border-r-4 hover:border-sky-800 w-full lg:w-auto"
                    onClick={closeMenu}
                  >
                    Air
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/Warehousing"
                    className="block p-4 text-black hover:text-blue-800 text-lg leading-6  hover:border-r-4 hover:border-sky-800 w-full lg:w-auto"
                    onClick={closeMenu}
                  >
                    Warehousing
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/Fulfillment"
                    className="block p-4 text-black hover:text-blue-800 text-lg leading-6  hover:border-r-4 hover:border-sky-800 w-full lg:w-auto"
                    onClick={closeMenu}
                  >
                    Fulfillment
                  </Link>
                </li>
              </ul>
            </li>
            <li className="mt-4 md:mt-0  w-full lg:w-auto">
              <Link
                to="/quote"
                className="block lg:inline p-4 text-black hover:text-blue-800 text-lg leading-6 navbar-link w-full lg:w-auto"
                onClick={closeMenu}
              >
                Quote
              </Link>
            </li>
            <li className="mt-4 md:mt-0  w-full lg:w-auto">
              <Link
                to="/contact"
                className="block lg:inline p-4 text-black hover:text-blue-800 text-lg leading-6 navbar-link w-full lg:w-auto"
                onClick={closeMenu}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
