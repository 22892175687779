/*import React from "react";

interface HeroBannerProps {
  title: string;
  content: string;
  image: string;
  ctaText: string; // Call to Action text
  ctaLink: string; // Call to Action link
}

const HeroBanner: React.FC<HeroBannerProps> = ({
  title,
  content,
  image,
  ctaText,
  ctaLink,
}) => {
  return (
    <section
      className="relative bg-cover bg-center bg-no-repeat h-2/3"
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div className="relative flex items-center justify-center lg:p-0 lg:py-28 py-12 lg:px-4 xl:px-0">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 z-10">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-6 lg:mb-8 text-5xl font-bold tracking-tight leading-none md:text-4xl xl:text-5xl text-white">
              {title}
            </h1>
            <p className="max-w-2xl mb-6 font-light lg:mb-8 md:text-lg lg:text-xl text-white">
              {content}
            </p>
            <a
              href={ctaLink}
              className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-indigo-500 hover:bg-indigo-300 focus:ring-4 focus:ring-indigo-500 dark:focus:ring-indigo-700"
            >
              {ctaText}
              <svg
                className="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
*/
import React from "react";

interface HeroBannerProps {
  title: string;
  content: string;
  image?: string;
  ctaText: string; // Call to Action text
  ctaLink?: string; // Call to Action link
  rightImage?: string; // Image on the right side
}

const HeroBanner: React.FC<HeroBannerProps> = ({
  title,
  content,
  image,
  ctaText,
  ctaLink,
  rightImage,
}) => {
  return (
    <section
      className="relative bg-current bg-center bg-no-repeat h-2/3 p-0"
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div className="relative flex items-center justify-center lg:p-0 lg:py-28 py-12 lg:px-4 xl:px-0">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 z-10">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-6 lg:mb-8 text-5xl font-bold tracking-tight leading-none md:text-4xl xl:text-5xl text-white">
              {title}
            </h1>
            <p className="max-w-2xl mb-6 font-light lg:mb-8 md:text-lg lg:text-xl text-white">
              {content}
            </p>
            <a
              href={ctaLink}
              className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-indigo-500 hover:bg-indigo-300 focus:ring-4 focus:ring-indigo-500 dark:focus:ring-indigo-700"
            >
              {ctaText}
              <svg
                className="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
          <div className="lg:col-span-5">
            <img
              src={rightImage}
              alt=""
              className="w-full h-full object-cover p-4 "
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
