import React from "react";

const AboutPage = () => {
  return (
    <section className="text-white py-6 lg:py-14 flex lg:justify-center lg:mx-auto">
      <div className="container mx-auto">
        <div className="">
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h1 className="text-3xl font-bold text-gray-900 mb-6">
              About Basically Limitless
            </h1>
            <p className="text-gray-700 mb-6">
              Basically Limitless is a dynamic logistics and transportation
              company headquartered in Rotterdam, Netherlands. We specialize in
              providing comprehensive cargo and transport solutions tailored to
              meet the diverse needs of our clients globally.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Our Global Presence
            </h2>
            <div className="mb-6">
              <h3 className="text-xl font-semibold text-gray-800">
                Headquarters:
              </h3>
              <p className="text-gray-700">
                <strong>Netherlands:</strong> Our main office is strategically
                located in Rotterdam, ensuring seamless coordination and
                management of our extensive network.
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-semibold text-gray-800">
                Regional Offices and Agencies:
              </h3>
              <div className="ml-4">
                <h4 className="text-lg font-semibold text-gray-800">
                  Middle East:
                </h4>
                <ul className="list-disc list-inside text-gray-700">
                  <li>Dubai</li>
                  <li>Turkey</li>
                </ul>

                <h4 className="text-lg font-semibold text-gray-800">Africa:</h4>
                <ul className="list-disc list-inside text-gray-700">
                  <li>Kenya</li>
                  <li>Uganda</li>
                  <li>Tanzania</li>
                  <li>Somalia</li>
                </ul>

                <h4 className="text-lg font-semibold text-gray-800">Asia:</h4>
                <ul className="list-disc list-inside text-gray-700">
                  <li>China</li>
                </ul>

                <h4 className="text-lg font-semibold text-gray-800">
                  North America:
                </h4>
                <ul className="list-disc list-inside text-gray-700">
                  <li>USA</li>
                  <li>Canada</li>
                </ul>

                <h4 className="text-lg font-semibold text-gray-800">Europe:</h4>
                <ul className="list-disc list-inside text-gray-700">
                  <li>United Kingdom</li>
                  <li>Netherlands</li>
                  <li>Germany</li>
                  <li>Belgium</li>
                  <li>Spain</li>
                  <li>Italy</li>
                  <li>France</li>
                </ul>
              </div>
            </div>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Our Services by Region
            </h2>
            <div className="mb-6">
              <h3 className="text-xl font-semibold text-gray-800">
                Western Europe:
              </h3>
              <p className="text-gray-700">
                <strong>Cargo and Truck Transport Services:</strong> We offer
                reliable and efficient cargo and truck transport solutions
                across Western Europe, ensuring timely and safe delivery of
                goods.
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-semibold text-gray-800">
                Netherlands:
              </h3>
              <p className="text-gray-700">
                <strong>Warehousing Services:</strong> In addition to transport
                services, we provide state-of-the-art warehousing solutions in
                the Netherlands, catering to diverse storage needs.
              </p>
              <p className="text-gray-700">
                <strong>Road Services:</strong> Our road transport services in
                the Netherlands are designed to offer seamless and
                cost-effective logistics solutions within the country.
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-semibold text-gray-800">Global:</h3>
              <p className="text-gray-700">
                <strong>Road, Air, and Sea Services:</strong> We deliver
                comprehensive road, air, and sea transport services across our
                operational regions, ensuring flexible and efficient logistics
                solutions tailored to meet the demands of different markets.
              </p>
            </div>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Why Choose Basically Limitless?
            </h2>
            <p className="text-gray-700 mb-4">
              At Basically Limitless, we are committed to delivering excellence
              in logistics and transportation. Our extensive network, coupled
              with our dedication to innovation and customer satisfaction, makes
              us a trusted partner for businesses worldwide. Whether it's
              domestic or international transport, warehousing, or comprehensive
              logistics solutions, we have the expertise and resources to meet
              your needs effectively.
            </p>
            <p className="text-gray-700 font-semibold">
              Join us at Basically Limitless, where your logistics needs know no
              boundaries.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutPage;
